/* eslint-disable default-case */
import React, { Component, useState } from "react";
import { useEffect } from "react";
import CheckoutForm from "../checkout-form";
import { useLocation } from "react-router-dom";
import SubscriptionHelper from "../../helper-methods/subscription-helper";
import Swal from "sweetalert2";

const SubscriptionWizard = ({ gotoSampleMessages }) => {
  const [screenType, setScreenType] = useState("screen1");
  const { search } = useLocation();

  const _checkIfRedirected = async () => {
    const query = new URLSearchParams(search);
    const paramField = query.get("pt");

    if (paramField?.length) {
      if (paramField === "s") {
        // Success
        await SubscriptionHelper.addUserToGroup();
        Swal.fire("You have successfully subscribed", "", "success");
      } else {
        // Error
      }
    }
  };

  useEffect(() => {
    _checkIfRedirected();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screenType]);

  const _renderStep = () => {
    switch (screenType) {
      case "screen1": {
        return (
          <>
            <div className="bigHeading">Welcome to <span>My Daily Dose of Goodness</span>.</div>

            {/* <div className="smallHeading">By subscribing to this service, you will automatically receive a philosophical text every day.</div>
              
            <p>The texts will be fresh takes on Stoic philosophies of great thinkers like Socrates at 450 B.C. through Plato, Aristotle, Epictetus, Marcus Arelius at 160 AD, plus other ancient and contemporary philosophers and authors.</p>
            
            <p> All the research, writing, and editing has been done for you. You don't even have to open an app or email. The text will drop right into your phone and notify you. <span className="highlight">The subscription rate is $6.19 per month or 20 cents a day.</span> The rate you join at is permanently locked in during your continuous subscription.   Browes the website then click the 7-day Free Trial icon. </p>

            <div className="trialDetails">
              <div className="fw-bold">
                <span className="sampleMessageLink fw-bold" onClick={gotoSampleMessages}>
                  Preview actual Daily Dose texts
                </span>{" "}
                below then click on 7-Day free trial link.
              </div>
              
              <button
                class="btn-get-started goButton"
                onClick={(e) => setScreenType("screen2")}
              >
                Trial Subscription &nbsp;{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div> */}

            <div className="smallHeading">The two guiding principles of this texting platform are Anonymity and Doing Good.</div>

            <p><b>ANONYMITY:</b> As a one-person company, I have no interest in building out a platform with my name and picture as the face of the company.   Marcus Arelius didn’t place much value, if any, in having his likeness displayed in public.    He preferred focusing on doing the right thing for the common good without notoriety. The more accolades and attention drawn to the individual, the greater the chance of compromising one’s focus on purpose with integrity.    Anonymity preserves the integrity of simply doing good for its own sake.   Doing Good is enough without recognition.  Few people even know who I am, and only a handful of those know I’m working on this project, exactly how it should be.   <span className="highlight">Customer support is at <a href="mailto:joel@MDDtext.com" className="link">joel@MDDtext.com</a></span></p>

            <p><b>DOING GOOD:</b> Regarding Doing Good, I have a two-pronged approach.    One, to share knowledge, insight, and encouragement with each subscriber to do good, to be good, to be better, and make a difference in the lives of those in their circle of influence.   And two, as the platform becomes profitable, the company will make direct donations to selected charities of appropriate amounts commensurate with monthly levels of profitability.    This is what I call honorable use of proceeds.</p>
          </>
        );
      }
      case "screen2": {
        return (
          <>
            <CheckoutForm />
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="content pt-4 pt-lg-0">{_renderStep()}</div>
    </>
  );
};

export default SubscriptionWizard;
